/* translate ATK event names to Mixpanel lingo */
const events = {
  ANCHOR_CTA_CLICK: 'Anchor CTA Click',
  ARTICLE_LINK_CLICKED: 'Article Link Clicked',
  BOOK_AD_CLICKTHROUGH: 'Book ad clickthrough',
  BOOK_ATTRIBUTION_CLICKTHROUGH: 'Book attribution clickthrough',
  BRAND_PAGE_AD: 'Brand Page Ad',
  BRAND_PAGE_ITEM_CLICK: 'Brand Page Item Click',
  BROWSE_FACET_SELECTED: 'Browse Facet Selected',
  BROWSE_RESULT_CLICKED: 'Browse Result Clicked',
  BUY_NOW_CLICK: 'Buy Now Click',
  BYLINE_CLICKED: 'Byline Clicked',
  BIO_PAGE_CLICKED: 'Bio Page Clicked',
  CANCEL_MEMBERSHIP_CLICK: 'Cancel Membership Click',
  CANCEL_SURVEY_SUBMITTED: 'Cancel Survey Submitted',
  CANCEL_SURVEY_VIEWED: 'Cancel Survey Viewed',
  COLLECTION_ITEM_CLICKTHROUGH: 'Collection Item Clickthrough',
  COMMENT_CREATED: 'ReaderCommentSubmit',
  COMMENTS_CLICK: 'Comments Click',
  COOKING_MODE_CLICK: 'Cooking Mode Click',
  CLEAR_BROWSE_FILTER: 'Clear Browse Filter',
  DISPLAY_AD: 'Display Ad',
  DOCUMENT_VIEWED: 'Document Viewed',
  DOCUMENT_PRINTED: 'Document Printed',
  EMAIL_CAPTURE: 'Email Capture',
  EMAIL_FRIEND: 'Email A Friend',
  END_MEMBERSHIP: 'End Membership',
  ERROR_BOUNDARY: 'React error boundary triggered',
  ERROR_PAGE: '404 Not Found',
  FAQ_CLICK: 'FAQ Click',
  FAVORITE_COLLECTION_ADDED: 'Favorite Collection Added',
  FAVORITE_COLLECTION_EDITED: 'Favorite Collection Edited',
  FAVORITE_COLLECTION_DELETED: 'Favorite Collection Deleted',
  FAVORITE_COLLECTION_ITEM_ADDED: 'Favorite Collection Item Added',
  FAVORITE_COLLECTION_ITEM_REMOVED: 'Favorite Collection Item Removed',
  FAVORITE_ADDED: 'Favorite Added',
  FAVORITE_RESULT_CLICKED: 'Favorite Result Clicked',
  FAVORITE_REMOVED: 'Favorite Removed',
  FAVORITE_FILTER_SELECTED: 'Favorite Filter Selected',
  GENERAL_RECIPE_TESTER_SIGNUP: 'General Recipe Tester Signup',
  GLOBAL_RESUBSCRIBE_EMAIL: 'Global Resubscribe Email',
  GUIDE_CAROUSEL_CLICKTHROUGH: 'Guide Carousel Clickthrough',
  GUIDE_CATEGORY_CLICKTHROUGH: 'Guide Category Clickthrough',
  GUIDE_HERO_CLICKTHROUGH: 'Guide Hero Clickthrough',
  GUIDE_ITEM_CLICKTHROUGH: 'Guide Item Clickthrough',
  HAT_CTA_CLICK: 'Hat CTA Click',
  HOME_PAGE_CATEGORY_ITEM_CLICK: 'Home Page Category Item Click',
  HOMEPAGE_FAVORITE_CLICK: 'Homepage Favorite Click',
  HOW_CAN_WE_HELP_CLICK: 'How Can We Help Click',
  HOW_TO_SIDEBAR_CLICKTHROUGH: 'CIO How To Sidebar',
  INLINE_VIDEO_PLAY: 'Inline Video Play',
  KEEP_MEMBERSHIP: 'Keep Membership',
  LANDING_PAGE_CLICK: 'Landing Page Click',
  LOAD_MORE_BUTTON_CLICKED: 'Load More Button Clicked',
  LOGIN_CLICK: 'Login Click',
  LINK_CLICK: 'Link Click',
  MICROUPSELL: 'Microupsell',
  MEMBERSHIP_AMEND_CONFIRMED: 'Membership Amend Confirmed',
  MEMBERSHIP_AMEND_FAILURE: 'Membership Amend Failure',
  MEMBERSHIP_CANCEL_CONFIRMED: 'Membership Cancel Confirmed',
  MEMBERSHIP_CANCEL_FAILURE: 'Membership Cancel Failure',
  MEMBERSHIP_CTA_ON_FREE: 'Membership CTA on Free',
  MEMBERSHIP_DETAILS_VIEWED: 'Membership Details Viewed',
  METER_BAR_DISPLAYED: 'Meter Bar Displayed',
  METER_BAR_INTERACTION: 'Meter Bar Interaction',
  MICRO_MEMBERSHIP_MESSAGE: 'Micro Membership Message',
  MORE_FROM: 'More From',
  NEWSLETTER_EMAIL_CAPTURE: 'Email Capture',
  NEWSLETTER_PREFERENCES: 'Newsletter Preferences',
  NEWSLETTER_SIGNUP_PRESENTED: 'Newsletter Signup Presented',
  OFFER_CLICK: 'Offer Click',
  PAGEVIEW: 'Page View',
  PAYWALL: 'Paywall',
  PAYWALL_LOGIN: 'Paywall Login',
  PAYWALL_WELCOME_BACK_LOGIN_PRESENTED: 'Paywall Welcome Back Login Presented',
  PPC_LANDING_CLICKED: 'PPC Landing Page Clicked',
  PIANO_FAILED_TO_FIRE: 'Piano Failed to Fire',
  PINNED_CTA_CLICK: 'Pinned CTA Click',
  PLAYER_CLICKTHROUGH: 'Player Clickthrough',
  PLAYLIST_CLICK: 'Playlist Video Click',
  PODCASTS_SUBSCRIBE_CLICKTHROUGH: 'Podcast Subscribe Clickthrough',
  PODCASTS_EPISODE_CLICKTHROUGH: 'Podcast Episode Clickthrough',
  PODCASTS_EMAIL_CAPTURE: 'Email Capture',
  PRODUCT_COLLECTION_AFFILIATE_CLICKTHROUGH:
    'Product Collection Reviewable Affiliate Clickthrough',
  PRODUCT_LIST_CLICKTHROUGH: 'Product List Clickthrough',
  PRODUCT_GRID_RETAILER_LIST: 'Product Grid Retailer List',
  PRODUCT_GRID_TOGGLE_ALL: 'Product Grid Toggle All',
  PRODUCT_GRID_TOGGLE_EXPANDED: 'Product Grid Toggle Expanded',
  PRODUCT_GRID_TOGGLE_COLLAPSED: 'Product Grid Toggle Collapsed',
  RATING_ADDED: 'Rating Added',
  RATING_CHANGE: 'Rating Change',
  RATING_REMOVE: 'Rating Remove',
  RETENTION_MESSAGE: 'Retention Message',
  QUICK_VIEW: 'Quick View',
  QUICK_VIEW_CLICK: 'Quick View Click',
  RECIPE_TAG_CLICK: 'Recipe Tag Click',
  RECIPE_LANDING_PAGE_CLICK: 'Recipe Landing Page Click',
  RECOMMENDATION_CAROUSEL: 'Recommendation Carousel',
  REGISTRATION_SUBMISSION: 'Free Registration Complete',
  RELATED_CONTENT_CLICKTHROUGH: 'Related Content Clickthrough',
  REVIEW_LANDING_PAGE_CLICK: 'Review Landing Page Click',
  REVIEW_LANDING_PAGE_BUY_NOW_CLICK: 'Buy Now Click',
  REVIEW_VIDEO_CLICK: 'Review Video Click',
  RECOMMENDATION_ADDED: 'Recommendation Added',
  RECOMMENDATION_REJECTED: 'Recommendation Rejected',
  RECOMMENDATION_VIEWED: 'Recommendation Viewed',
  RECENT_FAVORITE_CLICKED: 'My Recent Favorites Clicked',
  SAVE_OFFER_NOT_ELIGIBLE: 'Save Offer Not Eligible',
  SURVEY_CARD_VIEWED: 'Survey Card Viewed',
  SURVEY_CARD_COMPLETED: 'Survey Card Completed',
  SEE_CHART: 'See Chart',
  SEE_WHY: 'See Why',
  SCHOOL_COURSE_CLICKTHROUGH: 'School Related Content Clicked',
  SCHOOL_COURSE_PRESENTED: 'School Related Content Presented',
  SEARCH_FORM_FOCUS: 'Search Form Focus',
  SEARCH_FORM_SUBMITTED: 'Search Form Submitted',
  SITE_NAV_CLICK: 'Site Nav Click',
  SPONSOR_LOGO_CLICK: 'Sponsor Logo Click',
  STORY_TOGGLE: 'Story Toggle',
  TOC_CLICK: 'TOC Click',
  MEGA_MENU_CLICK: 'Mega Menu Click',
  MEGA_MENU_CLOSE: 'Mega Menu Close',
  MEGA_MENU_OPEN: 'Mega Menu Open',
  MEMBERSHIP_BLOCK: 'Membership Block',
  MEMBERSHIP_MODAL: 'Membership Modal',
  MADE_FOR_YOU_CLICKED: 'Made for you collection clicked',
  MY_COLLECTIONS_CLICKED: 'My collections clicked',
  VALUE_PROP_CLICKTHROUGH: 'Value Prop Clickthrough',
  VIDEO_INLINE_PLAY: 'Inline Video Play',
  VIDEO_RELATED_CONTENT_CLICKTHROUGH: 'More From',
  VIEW_INGREDIENTS_CLICK: 'View Ingredients Click',
  VOUCHER_WEB_REDEMPTION: 'Voucher Web Redemption',
  ATK_PRINT_OPTIONS: 'ATK Recipe Print Options',
  CCO_PRINT_OPTIONS: 'CCO Recipe Print Options',
  CIO_PRINT_OPTIONS: 'CIO Recipe Print Options',
};

// adding an event
// 1) add the key/value pair above
// 2) add matching entry to analytics/track.js
// 3) call analytics.track(YOUR_KEY, yourData) from somewhere

export default events;
