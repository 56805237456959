import React from 'react';

const NoJquery = `
/* eslint-disable no-useless-escape */
// eslint-disable-next-line no-redeclare
var GlobalComponents = GlobalComponents || {};
// =================================================
// For given key, find associated marketing codes
// =================================================
GlobalComponents.findMarketingCodes = function (marketingKey, codeType) {
  var codes = GlobalComponents.marketingCodes || [];
  return codes.filter(function (code) {
    return code.marketing_key === marketingKey && code.code_type === codeType;
  });
};

// =================================================
// Determine "device type" based on browser width
// =================================================
GlobalComponents.getDeviceType = function () {
  var bodyWidth = document.body.clientWidth;
  var deviceType = 'mobile';
  if (bodyWidth > 1100) {
    deviceType = 'desktop';
  } else if (bodyWidth > 768) {
    deviceType = 'tablet';
  }
  return deviceType;
};

// =================================================
// For given key & url, append URL-type marketing codes to URL
// =================================================
GlobalComponents.appendMarketingCodesToUrl = function (marketingKey, url, userSegment) {
  if (typeof url === 'undefined') return '';
  if (marketingKey === '' || typeof marketingKey === 'undefined') return url;

  // School query strings target SSO relay and need to be fully encoded
  var isSchool = typeof url !== 'undefined' && url.indexOf('onlinecookingschool') !== -1;
  var equalsSign = isSchool ? '%3D' : '=';
  var ampersand = isSchool ? '%26' : '&';
  var questionMark = isSchool ? '%3F' : '?';

  var newUrl = url;
  var deviceType = GlobalComponents.getDeviceType();
  var codes = GlobalComponents.findMarketingCodes(marketingKey, 'url');

  codes.forEach(function (code) {
    if (!(userSegment === code.user_segment || code.user_segment === 'any')) {
      return true;
    }
    if (newUrl.indexOf(code.parameter_key) === -1) {
      var mcode = code.parameter_key + equalsSign + code[deviceType];
      if ((newUrl.indexOf('?') !== -1 && !isSchool) || newUrl.indexOf('%3F') !== -1) {
        newUrl = newUrl + ampersand + mcode;
      } else {
        newUrl = newUrl + questionMark + mcode;
      }
    }
    return true;
  });
  return newUrl;
};

// =================================================
// For given key & url, append MIXPANEL-type marketing codes to URL
// =================================================
GlobalComponents.addMixpanelEventToLink = function (marketingKey, url) {
  if (marketingKey === '' || typeof marketingKey === 'undefined') {
    return url;
  }
  var newUrl = url;
  var deviceType = GlobalComponents.getDeviceType();
  var codes = GlobalComponents.findMarketingCodes(marketingKey, 'mixpanel');

  codes.forEach(function (code) {
    var mcode = code.parameter_key + '=' + code[deviceType];
    if (newUrl.indexOf('?') > -1) {
      newUrl = newUrl + '&' + mcode;
    } else {
      newUrl = newUrl + '?' + mcode;
    }
  });
  return newUrl;
};

GlobalComponents.trackFormSubmission = function (email, marketingKey, emailFormSubmitCallback) {
  var mpData = GlobalComponents.appendMarketingCodesToObj(marketingKey, { email: email });
  if (mpData && mpData.name) {
    mixpanel.track(
      mpData.name,
      mpData,
      emailFormSubmitCallback
    );
  } else {
    emailFormSubmitCallback();
  }
};

// =================================================
// For given key, append mixpanel-type marketing codes to obj
// =================================================
GlobalComponents.appendMarketingCodesToObj = function (marketingKey, obj) {
  if (marketingKey === '' || typeof marketingKey === 'undefined') {
    return obj;
  }

  var deviceType = GlobalComponents.getDeviceType();
  var codes = GlobalComponents.findMarketingCodes(marketingKey, 'mixpanel');
  var incodes = GlobalComponents.findMarketingCodes(marketingKey, 'url')[0];
  if (incodes) codes.push(incodes);
  codes.forEach(function (code) {
    obj[code.parameter_key] = code[deviceType];
  });
  return obj;
};

GlobalComponents.validateEmail = function (email) {
  var filter = /^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/;
  return filter.test(email);
};

GlobalComponents.applyMarketingCodes = function (segment) {
  var userSegment;
  if (segment === 'standard_member' || segment === 'standard_other') {
    userSegment = 'mulitsite';
  } else {
    userSegment = segment;
  }
  // =================================================
  // For each link within wrapper, apply associated marketing code
  // =================================================
  dry.each('[data-marketing-key]', function (el) {
    var marketingKey = el.getAttribute('data-marketing-key');
    var closestForm = el.closest('form');
    if (marketingKey) {
      if (closestForm && closestForm.getAttribute('action')) {
        var action = GlobalComponents.appendMarketingCodesToUrl(marketingKey, closestForm.getAttribute('action'), userSegment);
        closestForm.setAttribute('action', action);
      } else if (!closestForm) {
        var href = GlobalComponents.appendMarketingCodesToUrl(marketingKey, el.href, userSegment);
        el.setAttribute('href', href);
      }
    }
  });

  // =================================================
  // For each form within wrapper, apply associated marketing code to action
  // =================================================
  dry.each('.atkGobalWrapper form', function (form) {
    var marketingKey = form.getAttribute('data-marketing-key');
    if (marketingKey) {
      var formAction = GlobalComponents.appendMarketingCodesToUrl(
        marketingKey,
        form.action,
        userSegment
      );
      form.setAttribute('action', formAction);
      form.setAttribute('method', 'POST');
    }
  });

  // =================================================
  // search form, attach associated mixpanel event codes
  // =================================================
  function handleSearchFormSubmit(e) {
    e.preventDefault();

    var searchForm = e.currentTarget,
        loc = document.location,
        termValue = searchForm.querySelector('[name="q"]').value;

    var marketingKey = searchForm.getAttribute('data-marketing-key');
    var mpData = GlobalComponents.appendMarketingCodesToObj(marketingKey, { term: termValue });

    if (typeof window.atkBlueconic !== 'undefined') {
      window.atkBlueconic.trackEvent(mpData);
    } else if (typeof window.blueConicTracking !== 'undefined') {
      window.blueConicTracking.trackEvent(
        'SEARCH_FORM_SUBMITTED',
        mpData
      );
    }

    function searchFormSubmitCallback() {
      document.cookie = 'entryPoint=' + loc.pathname + loc.search + '; path=/';
      var url = searchForm.action || '';
      var urlWithQuery = termValue !== '' ? url + '?q=' + encodeURIComponent(termValue) : url;
      window.location = dry.subfolderUrlQueryRefinement
        ? dry.subfolderUrlQueryRefinement(urlWithQuery)
        : urlWithQuery;
    }

    if (typeof mixpanel !== 'undefined' && window.mixpanel.__loaded) { // eslint-disable-line
      mixpanel.track(mpData.name || 'Search Form Submitted', mpData, searchFormSubmitCallback);
    } else {
      searchFormSubmitCallback();
    }
  }

  dry.each('form.atkGlobalSearch', function (searchForm) {
    searchForm.addEventListener('submit', handleSearchFormSubmit);
  });

  // =================================================
  // search form focus, attach associated mixpanel event codes
  // =================================================
  function handleSearchInputFocus(e) {
    var searchInput = e.currentTarget;
    var marketingKey = searchInput.getAttribute('data-marketing-key');
    var mpData = GlobalComponents.appendMarketingCodesToObj(marketingKey, {});

    mixpanel.track(
      mpData.name,
      mpData
    );
  }

  dry.each('.atkGlobalSearch .atkGlobalSearch__input', function (searchInput) {
    searchInput.addEventListener('focus', handleSearchInputFocus);
  });
  // =================================================
  // For each email form within wrapper, attach associated mixpanel event codes
  // =================================================
  function handleDisplayFormSubmit(e) {
    e.preventDefault();
    var displayForm = e.currentTarget;
    var emailValue = displayForm.querySelector('[name="email"]').value;
    var messageBox = displayForm.querySelector('.form__message');
    var marketingKey = displayForm.getAttribute('data-marketing-key');
    var displayFormInput = displayForm.querySelector('.atkFormDisplay__input');

    if (!displayFormInput) {
      document.location.href = displayForm.getAttribute('action');
      return false;
    }

    // Validate presence & format
    if (!GlobalComponents.validateEmail(emailValue) && messageBox) {
      messageBox.innerText = 'Please enter a valid email address.';
      displayFormInput.setAttribute('aria-invalid', 'true');
      displayFormInput.focus();
      dry.unhide(messageBox);
      dry.addClass(messageBox, 'error');
      return false;
    }

    function emailFormSubmitCallback() {
      displayForm.submit();
    }

    GlobalComponents.trackFormSubmission(emailValue, marketingKey, emailFormSubmitCallback);
    displayForm.removeEventListener('submit', handleDisplayFormSubmit);
    return true;
  }
  dry.each('.atkFormDisplay__form', function (displayForm) {
    displayForm.addEventListener('submit', handleDisplayFormSubmit);
  });

  function handleSiteNavClick(evt) {
    var link = evt.target.closest('a');

    if (link) {
      var href = link.getAttribute('href');
      var sameWindow = link.getAttribute('target') !== '_blank';
      var isJarvisLink = link.classList.contains('jarvis-spa-link');
      var isJarvisPage = document.body.classList.contains('jarvis');
      var jarvisToJarvis = isJarvisLink && isJarvisPage;
      var shift = evt.metaKey || evt.shiftKey;
      /* eslint-disable no-underscore-dangle */
      var useCallback = shift === false
        && jarvisToJarvis === false
        && window.mixpanel.__loaded
        && sameWindow;

      var type = link.innerText ? link.innerText.toLowerCase() : null;
      if (link.classList.contains('atkGlobalSiteNav__menu--image')) {
        type = 'magazine photo';
      }
      if (type === 'reviews') {
        type = 'product reviews';
      }
      if (type === 'watch now') {
        type = 'play';
      }
      // forcing a copy of location.href b/c of issue with SPA navigation
      var location = document.location.href.split().join();
      var segments = document.location.pathname.split('/');
      var document_type = segments.length > 1 && segments[1] ? segments[1] : 'homepage';
      var callback;
      if (useCallback) {
        evt.preventDefault();
        callback = function () {
          document.location.href = href;
        };
        mixpanel.track(
          'Site Nav Click',
          {
            type: type,
            document_type: document_type,
            location: location
          },
          callback
        );
      }
      if (!sameWindow && !useCallback) {
        mixpanel.track(
          'Site Nav Click',
          {
            type: type,
            document_type: document_type,
            location: location,
          },
          { transport: 'sendBeacon' }
        );
      }
    }
  }

  dry.each('.atkGlobalSiteNav__menu--list, atkGlobalSiteNav__menu--list', function (siteNav) {
    siteNav.addEventListener('click', handleSiteNavClick);
  });
};
// eslint-disable-next-line no-redeclare
var GlobalActiveSiteNav = GlobalActiveSiteNav || {};
GlobalActiveSiteNav.currentUrl = window.location.href;
GlobalActiveSiteNav.siteNavSelector = '.atkGlobalSiteNav__menu a';

/**
 * Parse the urls for the links in the header. Mark the active
 * links if their pathname matches the pathname of the current page.
 * To avoid false positives we check the beginning of the string for a
 * match so that /magazines/<some-month>/recipes doesn't match on /recipes
 */

GlobalActiveSiteNav.activeMenuLinks = function () {
  var currentA = document.createElement('a');
  currentA.href = GlobalActiveSiteNav.currentUrl;
  var currentPath = currentA.pathname;
  // IE11 doesn't include the leading slash
  if (currentPath.charAt(0) !== '/') {
    currentPath = '/' + currentPath;
  }

  // Guides is a special case because the index pathname is under ER but the
  // detail pages are not under ER
  // Video (matching /episode) and Podcast are also special cases
  var isBgHome = currentPath === '/equipment_reviews/browse/buying-guides',
      isBgDetail = currentPath.indexOf('/buying-guides') === 0,
      isVideo = currentPath.indexOf('/video') !== -1,
      isEpisode = currentPath.indexOf('/episode') !== -1,
      isPodcast = currentPath.indexOf('/proof') !== -1,
      isERBrowseDetail = currentPath.indexOf('/equipment_reviews') === 0,
      isTTBrowseDetail = currentPath.indexOf('/taste_tests') === 0,
      siteNavNodes = GlobalActiveSiteNav.activeSiteNavLink || GlobalActiveSiteNav.siteNavSelector,
      siteKey = dry.getThemeSiteKey();

  dry.each(siteNavNodes, function (link) {
    // regex will take care of basic singular and plural matches
    // secondary match is to bucket videos under episode nav link
    var re = new RegExp('^' + link.pathname + '?');

    // On Play pages, compare the link's href to the current url and
    // make 'Shows' active when an item in its subnav is active.
    if (siteKey === 'play') {
      const linkBits = link.href.split('/');
      const compareSegment = linkBits[3];
      const matchesEpisode = compareSegment.includes('episode');
      const matchesVideo = compareSegment.includes('video');
      const pathSegment = dry.domainUrlFromSubfolderUrl(document.location.pathname).split('/')[1];

      // for special case where if the url includes 'video', we want the link
      // that includes 'episode' to be active and vice versa
      const videoToEpisode = pathSegment.includes('video') && matchesEpisode;
      const episodeToVideo = pathSegment.includes('episode') && matchesVideo;
      const isShow = ['whats-eating-dan', 'perfectly-seasonal'].includes(pathSegment);
      const pathMatch = pathSegment && compareSegment
                        && (compareSegment.includes(pathSegment)
                        || videoToEpisode
                        || episodeToVideo
                        || isShow);
      const mobileShowsLink = document.querySelector('.atkOffsitePanel .play-subnav-parent');
      if (mobileShowsLink && (isVideo || isEpisode)) {
        dry.activate(mobileShowsLink);
      }

      if (dry.domainSiteKeyFromUrl(link.href) === dry.siteKeyFromPathname() && pathMatch) {
        dry.activate(link);
        if (link.parentNode.classList.contains('play-subnav__item')) {
          dry.activate(document.querySelector('.play-subnav-parent'));
        }
      } else if (isShow) {
        dry.activate(document.querySelector('.play-subnav-parent'));
      }
    } else if (
      link.pathname !== '/'
      && (!isBgHome || (isBgHome && link.pathname !== '/equipment_reviews'))
      && (
        re.test(currentPath)
        || isBgDetail && link.pathname.indexOf('/buying-guides') !== -1
        || isVideo && link.pathname.indexOf('/episode') !== -1
        || isPodcast && link.href.indexOf('/proof') !== -1
      )
    ) {
      dry.activate(link);
    } else if (
      link.pathname === '/reviews'
      && (isERBrowseDetail || isTTBrowseDetail)
    ) {
      dry.activate(link);
    }
  });
};

var GlobalSubNavClick = GlobalSubNavClick || {};

GlobalSubNavClick.removeSubNav = function () {
  document.body.addEventListener('click', function (evt) {
    var expandableMenu = evt.target.closest('.expandable');
    var isExpandedMenu = expandableMenu && dry.hasClass('expanded', expandableMenu) || false;
    // we we only want this to work for touch enabled devices
    // See: https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js
    var isTouchable = (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch);
    var isSidemenu = evt.target.closest('.atkOffsitePanel');
    if ((isTouchable || isSidemenu) && isExpandedMenu) {
      if (!evt.target.closest('.atkGlobalSiteNav__menu--link')) {
        dry.removeClass('expanded', '.atkGlobalSiteNav__menu--item');
      }
    }
  });
};

document.addEventListener('DOMContentLoaded', function () {
  GlobalActiveSiteNav.activeSiteNavLink = document.querySelectorAll(
    GlobalActiveSiteNav.siteNavSelector
  );
});
/* eslint-disable */

var player, playerContainer, videoContainer, videoPlaceholder, videoDescription;

function loadYTScript() {
  var firstScriptTag = document.getElementsByTagName('script')[0];
  var tag = document.createElement('script');
  tag.id = 'iframe-demo';
  tag.src = 'https://www.youtube.com/iframe_api';
  tag.async = !0;

  firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}

function refreshVideoVars(){
  playerContainer = document.getElementById('player');
  videoContainer = document.querySelector('.video-swap');
  videoPlaceholder = document.querySelector('.video__placeholder');
  videoDescription = document.querySelector('.video__description');
}

function onYouTubeIframeAPIReady() {
  player = new YT.Player('player', {
    events: {
      'onReady': onPlayerReady,
      'onStateChange': onPlayerStateChange
    }
  });
}

function onPlayerReady(event) {
  refreshVideoVars();
  videoContainer.addEventListener('click', function(event){
    videoPlaceholder.style.zIndex = '-1';
    videoDescription.style.zIndex = '-1';
    playerContainer.style.zIndex = '3';
    player.playVideo();
  });
}

function onPlayerStateChange(event) {
  refreshVideoVars();
  if(event.data === 0) {
    videoPlaceholder.style.zIndex = '1';
    videoDescription.style.zIndex = '1';
    playerContainer.style.zIndex = '-1';
  }
}

function onZypePlayerReady(videoId) {
  var z_playerContainer  = document.getElementById(videoId);
  var z_videoContainer   = document.getElementById('video-swap-' + videoId);
  var z_videoPlaceholder = z_videoContainer.querySelector('.video__placeholder');
  var z_videoDescription = z_videoContainer.querySelector('.video__description');
  var z_videoAction      = z_videoContainer.querySelector('.video__description--action');
  var z_videoDescriptionHeader = z_videoContainer.querySelector('.video__description--header');

  z_videoContainer && z_videoContainer.addEventListener('click', function(event){
    z_videoAction.style.zIndex = '-1';
    z_videoPlaceholder.style.zIndex = '-1';
    z_videoDescription.style.zIndex = '-1';
    z_videoDescriptionHeader.style.zIndex = '-1';
    z_playerContainer.style.zIndex = '3';
  });
}

document.addEventListener('DOMContentLoaded', function () {
  refreshVideoVars();
  if (playerContainer) {
    loadYTScript();
  }
});
var GlobalFooter = GlobalFooter || {};

GlobalFooter.init = function () {
  GlobalFooter.emailForm = document.getElementById('global-footer-email-form');
  if (!GlobalFooter.emailForm) return;

  GlobalFooter.emailInput = GlobalFooter.emailForm.querySelector('.form__email');
  GlobalFooter.emailMessage = GlobalFooter.emailForm.querySelector('.form__message');

  GlobalFooter.returnMessage = function (messageClass, el, message) {
    dry
      .removeClass(GlobalFooter.emailMessage, 'hidden', 'success', 'error')
      .addClass(GlobalFooter.emailMessage, messageClass);
    el.innerText = message;
    if (messageClass === 'error') {
      GlobalFooter.emailInput.focus();
      GlobalFooter.emailInput.setAttribute('aria-describedby', 'global-footer-email-form__error');
    } else {
      GlobalFooter.emailInput.removeAttribute('aria-describedby');
    }
  };

  GlobalFooter.emailForm.addEventListener('submit', function (evt) {
    evt.preventDefault();
    evt.stopImmediatePropagation();

    var messageContent = GlobalFooter.emailMessage,
        parentForm = GlobalFooter.emailForm,
        sEmail = GlobalFooter.emailInput.value;

    // Validate presence of email
    if (sEmail.trim().length === 0) {
      GlobalFooter.returnMessage(
        'error',
        messageContent,
        'Email is required.'
      );
      return false;
    }

    // Validate format of email
    if (!GlobalFooter.validateEmail(sEmail)) {
      GlobalFooter.returnMessage(
        'error',
        messageContent,
        'Please provide a valid email address.'
      );
      return false;
    }

    var marketingKey = parentForm.getAttribute('data-marketing-key');

    dry.api.post(
      parentForm.getAttribute('action'),
      function () {
        dry.hide(parentForm.querySelectorAll('input, .form__instructions, .form__info'));
        GlobalFooter.returnMessage(
          'success',
          messageContent,
          'Thank you for signing up for our newsletter.'
        );

        GlobalComponents.trackFormSubmission(sEmail, marketingKey);
      },
      function () {
        GlobalFooter.returnMessage(
          'error',
          messageContent,
          'Unable to process your request at this time.'
        );
      },
      {
        data: { email: sEmail },
      }
    );
    return true;
  });

  var filter = /^(([^<>()[]\\.,;:s@"]+(.[^<>()[]\\.,;:s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/;
  GlobalFooter.validateEmail = function (sEmail) {
    return filter.test(sEmail);
  };
};
// eslint-disable-next-line no-redeclare
var globalHeader = globalHeader || {};
globalHeader.setGlobalLevelAttr = function () {
  globalHeader.siteKey = typeof globalSiteKey !== 'undefined'
    ? globalSiteKey
    : (dry.siteKey || 'atk');

  // always point to atk credentials to avoid issues with basic auth and kraken
  // to be updated when kraken issue is resolved.
  globalHeader.initialized = false;
  globalHeader.deviceType = dry.deviceType;
};

globalHeader.updateAnchor = function (text, href, el) {
  if (typeof el === 'string') el = document.querySelector(el);
  el.innerText = text;
  el.setAttribute('href', dry.subfolderUrl(href));
};

// ==========================================
// [showLoggedInState] Update header html to
//  reflect current user data.
// ==========================================
globalHeader.showLoggedInState = function () {
  // // 1) Show logout link
  dry.unhide('.account-nav');

  dry.each(
    '.atkGlobalSiteNav__menu #hamburger-login-logout',
    globalHeader.updateAnchor.bind(this, 'Logout', '/sign_out')
  );

  // 2) Show favorites
  dry.unhide('.atkGlobalHeader__favorites');

  // 3) Update dropdown menu to show user name
  if (dry.user.currentUser && dry.user.currentUser.firstName) {
    dry.each('.atkAccountNav__trigger--link .is-not-mobile-display', function (el) {
      el.innerText = dry.user.currentUser.firstName;
    });
  }

  // 4) Update CTA boxes based on API user info
  globalHeader.loadCtas();
};

globalHeader.showLoggedOutState = function () {
  // 1) Show logout link
  var loggedOutEls = document.querySelectorAll('.login-link, .atkGlobalHeader__support, .atkGlobalFooter__form, .atkGlobalFooter__actions--header');
  if (loggedOutEls.length > 0) {
    dry.unhide(loggedOutEls);
    dry.removeClass('no-search', loggedOutEls);
  }

  dry.each(
    '.atkGlobalSiteNav__menu #hamburger-login-logout',
    function (el) {
      globalHeader.updateAnchor('Log In', '/sign_in', el);
    }
  );

  // 2) Update CTA button based on API user info
  globalHeader.loadCtas();

  // 3) Show newsletter email input
  dry.unhide('#cv-left-nav-email-div');

  // 4) Hide global header toolbar for play on mobile
  var globalHeaderToolbar = document.getElementById('atkGlobalHeaderToolbar');
  if ((globalHeader.siteKey === 'play') && (window.innerWidth < 768)) {
    dry.hide(globalHeaderToolbar);
  } else {
    dry.unhide(globalHeaderToolbar);
  }
};

// ==========================================
// [loadCtas] based on user segment, load
// and display site nav CTAs
// ==========================================
globalHeader.loadCtas = function () {
  if (typeof globalCtaData === 'undefined' || typeof globalCtaData.user_segments === 'undefined') return;
  // NOTE - this is weird - why not just add 'hidden'?
  var hideCta = globalHeader.updateAnchor.bind(this, '', '#');

  // Standard user should use the same CTA package as multisite
  var segment = dry.contentAccessUserSegment;
  if (segment === 'standard_member' || segment === 'standard_other') {
    segment = 'multisite';
  }

  // Load on-page json that holds site-header CTA attributes
  var ctaDisplays = globalCtaData.user_segments[segment];
  var ctaKeys = ['cta_0', 'cta_1'];
  ctaKeys.forEach(function (key, i) { // eslint-disable-line consistent-return
    var cta = ctaDisplays[key];
    if (cta === undefined) {
      return true;
    }

    var ctaBox = document.querySelector('.atkGlobalSiteHeader__cta--item.cta-' + i);
    if (!cta.display_cta) {
      hideCta('#cta_' + i + '_primary_mobile');
      hideCta('#cta_' + i + '_secondary_mobile');
      return true;
    }

    dry.each('.atkFormDisplay__toggle--primary', function (el) {
      var nearestForm = el.closest('form');
      el.innerText = cta.cta_primary_title_text;
      el.setAttribute('data-cta_primary_show_form', cta.cta_primary_show_form);
      if (nearestForm) {
        nearestForm.setAttribute('action', dry.subfolderUrl(cta.cta_primary_title_url));
      } else {
        el.setAttribute('href', dry.subfolderUrl(cta.cta_primary_title_url));
      }
    }, ctaBox);

    dry.each('.atkFormDisplay__header', function (el) {
      el.innerText = cta.cta_primary_form_title;
    }, ctaBox);

    dry.each('.atkFormDisplay__form', function (el) {
      el.setAttribute('action', dry.subfolderUrl(cta.cta_primary_form_url));
    }, ctaBox);

    dry.each('.atkFormDisplay__toggle--secondary', function (el) {
      if (cta.display_secondary_link) {
        el.innerText = cta.cta_secondary_link_title;
        el.setAttribute('href', dry.subfolderUrl(cta.cta_secondary_link_url));
        el.setAttribute('target', '_blank');
        el.style.display = 'block';
      } else {
        el.style.display = 'none';
      }
    }, ctaBox);

    // Set mobile cta links - NOTE - are there more than one with same id?
    dry.each('#cta_' + i + '_primary_mobile', function (el) {
      el.text = cta.cta_primary_title_text;
      el.setAttribute('href', dry.subfolderUrl(cta.cta_primary_title_url));
    });

    dry.each('#cta_' + i + '_secondary_mobile', function (el) {
      el.text = cta.cta_secondary_link_title;
      el.setAttribute('href', dry.subfolderUrl(cta.cta_secondary_link_url));
      el.setAttribute('target', '_blank');
    });

    dry.unhide(ctaBox);

    // Hide mobile featured ctas for logged in user
    var currentUser = dry.user.currentUser;
    var ctaBoxLoggedOut = ctaBox.classList.contains('logged-out');
    var loggedInUser = currentUser && !ctaBoxLoggedOut;
    var featuredCtas = document.querySelector('.atkMembershipNav--featured');

    if (loggedInUser) {
      dry.hide(featuredCtas);
    } else {
      addSignInListener();
    }
  });

  var cta = ctaDisplays.cta_2;
  if (cta && cta.display_cta) {
    dry.each('.cta-2', function (ctaBox) {
      // update Cta Toggles
      dry.each('.atkFormDisplay__toggle--primary', function (el) {
        var nearestForm = el.closest('form');
        el.innerText = cta.cta_primary_title_text;
        if (nearestForm) {
          nearestForm.setAttribute('action', dry.subfolderUrl(cta.cta_primary_title_url));
        } else {
          el.setAttribute('href', dry.subfolderUrl(cta.cta_primary_title_url));
        }
        el.setAttribute('data-cta_primary_show_form', cta.cta_primary_show_form);
      }, ctaBox);

      // update Cta headers
      dry.each('.atkFormDisplay__header', function (el) {
        el.innerText = cta.cta_primary_form_title;
      }, ctaBox);

      // update Cta forms
      dry.each('.atkFormDisplay__form', function (el) {
        el.setAttribute('action', dry.subfolderUrl(cta.cta_primary_form_url));
      }, ctaBox);

      var currentUser = dry.user.currentUser;
      var ctaBoxLoggedOut = ctaBox.classList.contains('logged-out');

      var loggedInUser = currentUser && !ctaBoxLoggedOut;
      var loggedOutUser = !currentUser && ctaBoxLoggedOut;
      if (loggedInUser || loggedOutUser) {
        dry.unhide(ctaBox);
      }
    });
  }

  // After loading in the appropriate CTAs in the site header, load up assoc. marketing codes
  GlobalComponents.applyMarketingCodes(dry.segment);
  // Override pinned nav form producing elements
  if (GlobalComponents.getDeviceType() !== 'desktop') {
    // CTA Button
    var formEl = document.querySelector('.atkPinnedHeader__cta .atkFormDisplay__form');
    var formAction = formEl && formEl.getAttribute('action') || null;
    var link = document.querySelector('.atkPinnedHeader__cta a.atkFormDisplay__toggle--primary');
    var showForm = link && link.getAttribute('data-cta_primary_show_form') || null;

    if (showForm === 'true') {
      link.setAttribute('href', dry.subfolderUrl(formAction));
      link.setAttribute('data-cta_primary_show_form', false);
    }
  }
};

globalHeader.restoreDefaultState = function () {
  var selectors = [
    '.atkGlobalFooter__form',
    '.atkGlobalFooter__actions--header',
    '.atkGlobalHeader__cta',
    '.login-link',
    '.atkGlobalHeader__support',
    '.account-nav',
    '.atkGlobalHeader__favorites',
    '.atkGlobalSiteHeader__cta--item.cta-0',
    '.atkGlobalSiteHeader__cta--item.cta-1',
    '.is-not-mobile-display.cta-2'
  ];
  dry.hide(selectors.join(', '));
  dry.addClass('no-search', '.atkGlobalFooter');
  delete globalHeader.initialized;
};

globalHeader.initializeState = dry.debounce(function () {
  var init = globalHeader.initialized;
  if (init !== true) {
    globalHeader.setGlobalLevelAttr();
    var authToken = dry.getCookie('auth_token');
    if (authToken) {
      dry.events.subscribe('user:get', function () {
        globalHeader.showLoggedInState();
      });
    } else {
      globalHeader.showLoggedOutState();
    }
    GlobalFooter.init();
  }
  globalHeader.setSiteBannerVisibility();
  globalHeader.iosSmartBannerVisibility();
  GlobalActiveSiteNav.activeMenuLinks();
  globalHeader.initialized = true;
}, 250, true);

globalHeader.resetState = function () {
  globalHeader.restoreDefaultState();
  globalHeader.initializeState();
};

globalHeader.setSiteBannerVisibility = function () {
  var bannerElementNodes = document.querySelectorAll('.atkGlobalAnnouncement');
  var bannerElements = Array.prototype.slice.call(bannerElementNodes);
  var currentPath = document.location.pathname;
  var supportContactModal = document.querySelector('.support__contact--modal');
  var userSegment = dry.segment;
  var siteBanner = bannerElements.find(function (bannerElement) {
    var announcementId = bannerElement.dataset.announcement;
    var bannerIsActive = false;
    var bannerIsNew = announcementId && typeof dry.getCookie(announcementId) === 'undefined';
    if (bannerIsNew && userSegment) {
      try {
        var segments = JSON.parse(bannerElement.dataset.userSegment);
        if (Array.isArray(segments)) {
          bannerIsActive = (segments.length > 0) ? segments.includes(userSegment) : true;
        }
      } catch (err) {
        //
      }
    }
    return bannerIsActive;
  });
  if (siteBanner && dry.deviceType === 'mobile' && dry.siteKey === 'atk' && currentPath.includes('/articles/')) {
    siteBanner.classList.add('articles-mobile');
  }
  dry.unhide(siteBanner);
  if (siteBanner && supportContactModal) supportContactModal.style.top = siteBanner.offsetHeight + 'px';
};

globalHeader.iosSmartBannerVisibility = function () {
  var existingMetaTag = document.querySelector('meta[name="apple-itunes-app"]');
  var isMultisite = dry.segment === 'multisite';
  if (!existingMetaTag && isMultisite) {
    var link = document.createElement('meta');
    link.setAttribute('name', 'apple-itunes-app');
    link.content = 'app-id=1365223384';
    document.getElementsByTagName('head')[0].appendChild(link);
  }
};

if (typeof dry.events !== 'undefined') {
  dry.events.subscribe('user:get', function () {
    dry.events.publish('dry:authentication:loaded');
  });
}

function isReferable() {
  var shouldRefer = true;
  var nonReferablePaths = ['user', 'sign_in', 'sign_out', '/'];
  for (var i = nonReferablePaths.length; i > 0; i -= 1) {
    if (window.location.pathname.indexOf(nonReferablePaths[i]) > 0) {
      shouldRefer = false;
      break;
    }
  }
  return shouldRefer;
}

function addSignInListener() {
  /** @type {HTMLAnchorElement} */
  const a = document.querySelector('#play-sign-in');
  if (!a) return;
  a.addEventListener('click', function (evt) {
    evt.preventDefault();
    var logInLink = evt.target.getAttribute('href');
    if (logInLink.indexOf('next') === -1 && isReferable()) {
      var character = logInLink.indexOf('?') === -1 ? '?' : '&',
          nextParam = character + 'next=' + encodeURIComponent(window.location.pathname + window.location.search);
      logInLink += nextParam;
    }
    setTimeout(function () {
      window.location = logInLink;
    }, 0);
  });
}
;
/* eslint-disable */

var globalHeader = globalHeader || { UIinitialized: false };
globalHeader.initializeUI = function () {
  if (globalHeader.UIinitialized === true) return;
  globalHeader.UIinitialized = true;

  var globalHeaderEl = document.querySelector('.atkGobalWrapper'); // note spelling mistake
  var activeClass = 'active';
  var expandedClass = 'expanded';

  if (!globalHeaderEl) return;

  //----------------------------------------------------------------------------
  // Play site nav hover treatment
  //----------------------------------------------------------------------------
  function applyHoverStateToParent(evt) {
    var el = evt.currentTarget;
    const parentLink = document.querySelector('#play-subnav-parent');
    if (parentLink) parentLink.style.backgroundImage = 'linear-gradient(transparent 91%, #6ba6aa 91%)';
  }

  function removeHoverStateFromParent(evt) {
    var el = evt.currentTarget;
    const parentLink = document.querySelector('#play-subnav-parent');
    if (parentLink) parentLink.style.removeProperty('background-image');
  }

  dry.each('.play-subnav__item a', function (el) {
    el.addEventListener('mouseenter', applyHoverStateToParent);
    el.addEventListener('mouseleave', removeHoverStateFromParent);
  });

  function closeAccordionItem(accordionItem) {
    dry.deactivate(accordionItem);
    dry.addClass(accordionItem, 'closed');
    var btn = accordionItem.querySelector('.accordion-button');
    if (btn) btn.setAttribute('aria-expanded', 'false');
  }

  function openAccordionItem(parent) {
    dry.removeClass(parent, 'closed');
    setTimeout(function () {
      dry.toggleActive(parent);
    }, 50);
    var btn = parent.querySelector('.accordion-button');
    if (btn) btn.setAttribute('aria-expanded', 'true');
  }

  //----------------------------------------------------------------------------
  // Play Mega Menu
  //----------------------------------------------------------------------------
  var playMegaMenuTriggerButton = document.querySelector('.play-mega-menu-button');
  var playMegaMenuClass = '.play-mega-menu';

  function megaMenuKeyDown(evt, showsButton, showsSelector) {
    var megaMenu = document.querySelector(playMegaMenuClass);
    if (megaMenu) {
      var megaMenuFocusableElements = megaMenu.querySelectorAll('a');
      var megaMenuFirstFocusable = megaMenuFocusableElements[0];
      var megaMenuLastFocusable = megaMenuFocusableElements[megaMenuFocusableElements.length - 1 ];
      // if escape is pressed while mega menu is open, close it
      if (evt.key === 'Escape') {
        toggleShowsContent(showsButton, showsSelector);
      }
      // if tab is not pressed, ignore other key inputs
      if (evt.key !== 'Tab') {
        return;
      }
      /**
       * If tab and shift keys are pressed, and user is on first focusable
       * element in mega menu, then close mega menu
       * else if tab key is pressed and user is on last focusable element
       * in mega menu, then close mega menu
       * 
       */
      if (evt.shiftKey) {
        if (document.activeElement === megaMenuFirstFocusable) {
          // prevent default here to avoid skipping an extra focusable element
          evt.preventDefault();
          toggleShowsContent(showsButton, showsSelector);
        }
      } else {
        if (document.activeElement === megaMenuLastFocusable) {
          // prevent default here to avoid skipping an extra focusable element
          evt.preventDefault();
          toggleShowsContent(showsButton, showsSelector);
        }
      }
    }
  }

  function isShowsExpanded(showsWrapper) {
    return showsWrapper && !showsWrapper.classList.contains('hidden');
  }

  function handleShowsWrapperClick(evt) {
    var showLink = evt.target.closest('.play-mega-menu-show__link');
    if (showLink) {
      evt.preventDefault();
      mixpanel.track(
        'Mega Menu Click',
        {
          device_type: dry.getDeviceType(document.body.clientWidth),
          sub_category_name: showLink.dataset.showName,
          type: showLink.dataset.type
        },
        function () {
          document.location.href = showLink.href;
        }
      );
    }
  }

  function toggleShowsContent(showsButton, showsSelector) {
    var showsWrapper = document.querySelector(showsSelector);
    if (showsWrapper) {
      function showsHandleKeyDown(evt) {
        megaMenuKeyDown(evt, showsButton, showsSelector);
      }
      var megaMenuDeviceType = dry.getDeviceType(document.body.clientWidth);
      if (isShowsExpanded(showsWrapper)) {
        showsButton.setAttribute('aria-expanded', 'false');
        showsWrapper.classList.add('hidden');
        if (showsSelector === playMegaMenuClass) {
          document.removeEventListener('keydown', showsHandleKeyDown);
        }
        showsWrapper.removeEventListener('click', handleShowsWrapperClick);
        showsButton.focus();
        if (mixpanel) {
          mixpanel.track('Mega Menu Close', { device_type: megaMenuDeviceType, type: 'Shows' });
        }
      } else {
        showsButton.setAttribute('aria-expanded', 'true');
        showsWrapper.classList.remove('hidden');
        if (showsSelector === playMegaMenuClass) {
          document.addEventListener('keydown', showsHandleKeyDown);
        }
        showsWrapper.addEventListener('click', handleShowsWrapperClick);
        // Focus on first link in mega menu when it is opened
        showsWrapper.querySelector('.play-mega-menu-show__image-link').focus();
        if (mixpanel) {

          mixpanel.track('Mega Menu Open', { device_type: megaMenuDeviceType, type: 'Shows' });
        }
      }
    }
  }

  if (playMegaMenuTriggerButton) {
    playMegaMenuTriggerButton.addEventListener(
      'click',
      function() {
        toggleShowsContent(playMegaMenuTriggerButton, playMegaMenuClass);
      }
    );
  }

  //----------------------------------------------------------------------------
  // Accordion sections in footer
  //----------------------------------------------------------------------------
  function toggleAccordion(evt) {
    var el = evt.currentTarget,
      accordionItemClassname = 'atkGlobalAccordion__item',
      accordionItemSelector = '.' + accordionItemClassname,
      parent = el.parentNode,
      parentItem = dry.hasClass(accordionItemClassname, parent),
      accordionGroup = el.closest('.atkGlobalAccordion__group'),
      accordionItem = el.closest(accordionItemSelector);

    if (parentItem && dry.isActive(parent)) {
      // if clicked on an accordion item directly, and that item is active, then deactivate
      closeAccordionItem(accordionItem);
    } else {
      // remove 'active' from all items within the closest group and the parent
      dry.each(accordionItemSelector, closeAccordionItem, accordionGroup);
      if (parentItem) openAccordionItem(parent);
    }
  }

  dry.each('.atkGlobalAccordion__header', function (el) {
    el.addEventListener('click', toggleAccordion);
  });

  //----------------------------------------------------------------------------
  //  Announcement Hide
  //----------------------------------------------------------------------------
  var announcementSelector = '.atkGlobalAnnouncement',
    announcementCloseSelector = '.atkGlobalAnnouncement__close',
    announcementCloses = document.querySelectorAll(announcementCloseSelector),
    supportContactModal = document.querySelector('.support__contact--modal'),
    announcementBodyClass = 'withGlobalAnnouncement';

  if (document.querySelector(announcementSelector)) {
    document.body.classList.add(announcementBodyClass)
  }

  dry.each(announcementCloseSelector, function (announcementClose) {
    announcementClose.addEventListener('click', function (evt) {
      var announcement = evt.target.closest(announcementSelector)
      document.cookie = announcement.dataset.announcement + '=true;path=/';
      if (supportContactModal) supportContactModal.style.top = "0";
      announcement && dry.fadeOut(announcement, function () {
        document.body.classList.remove(announcementBodyClass);
      });
    });
  });

  //----------------------------------------------------------------------------
  //  Form Display
  //----------------------------------------------------------------------------
  var formDisplayToggleSelector = '.atkFormDisplay__toggle--primary',
    formDisplayToggles = globalHeaderEl.querySelectorAll(formDisplayToggleSelector);


  var focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])';

  /**
   * For acccessibility purposes, when a user opens a dialog drop down or modal,
   * focus tabbing should be constrained to just focusable elements within the
   * dialog.
   *
   * solution based on this article: https://uxdesign.cc/how-to-trap-focus-inside-modal-to-make-it-ada-compliant-6a50f9a70700
   */
  function lockFocus(keyPressed, parentEl) {
    var firstFocusable = parentEl.querySelectorAll(focusableElements)[0];
    var focusableContent = parentEl.querySelectorAll(focusableElements);
    var lastFocusable = focusableContent[focusableContent.length - 1];
    var isTabPressed = keyPressed.key === 'Tab' || keyPressed.keyCode === 9;

    if (!isTabPressed) {
      return;
    }

    if (keyPressed.shiftKey) {
      // IF shift key is pressed AND user is focusing on the first focusable child
      // element, move focus to the last focusable child element.
      if (document.activeElement === firstFocusable) {
        lastFocusable.focus();
        keyPressed.preventDefault();
      }
    } else {
      // IF only the tab key is pressed AND user is focusing on the last focusable
      // child element, move focus to first focusable child element.
      if (document.activeElement === lastFocusable) {
        firstFocusable.focus();
        keyPressed.preventDefault();
      }
    }
  }

  function dialogueFocusHandler(keyPressed, parentEl) {
    var firstFocusable = parentEl.querySelectorAll(focusableElements)[0];
    var focusableContent = parentEl.querySelectorAll(focusableElements);
    var lastFocusable = focusableContent[focusableContent.length - 1];
    var isTabPressed = keyPressed.key === 'Tab' || keyPressed.keyCode === 9;

    if (!isTabPressed) {
      return;
    }

    // IF shift key is pressed AND user is focusing on the first focusable child
    // or if user is focused on last focusable child, we close the dialogue
    if ((
      keyPressed.shiftKey && document.activeElement === firstFocusable) ||
      document.activeElement === lastFocusable
    ) {
      dry.toggleActive(parentEl);
      dry.events.publish('form-toggle:close');
    }
  }

  if (formDisplayToggles.length > 0) {
    var ctaWrapperSelector = '.atkGlobalSiteHeader__cta',
      formDisplaySelector = '.atkFormDisplay',
      formDisplayActiveSelector = '.atkFormDisplay.' + activeClass,
      ctaWrapper = globalHeaderEl.querySelector(ctaWrapperSelector),
      formDisplay = globalHeaderEl.querySelector(formDisplaySelector),
      formDisplayActive = globalHeaderEl.querySelector(formDisplayActiveSelector);

    function formDisplayView(evt) {
      var el = evt.currentTarget,
        showForm = el.dataset.cta_primary_show_form === 'true',
        closestForm = el.closest('form');

      if (showForm) {
        evt.preventDefault();
        if (dry.isActive(el)) {
          dry.deactivate(el);
        } else {
          var closestCtaWrapper = el.closest(ctaWrapperSelector),
            activeSiblings = closestCtaWrapper && closestCtaWrapper.querySelectorAll(formDisplayActiveSelector),
            closestForm = el.closest(formDisplaySelector),
            closestFormWrapper = closestForm.querySelector('.atkFormDisplay__dialog'),
            closestInput = closestForm && closestForm.querySelector('.atkFormDisplay__input');

          if (activeSiblings && activeSiblings.length > 0) {
            dry.each(activeSiblings, dry.deactivate.bind(dry));
            dry.activate(el.closest(formDisplaySelector));
          }

          // enable user to close form popup OR modal using the escape key
          function handleKeyDown(e) {
            if (e.keyCode === 27) {
              if (document.querySelector('.atkGlobalModal')) {
                e.stopPropagation();
                closeModal();
                var activeFormEmailUseButton = closestForm.querySelector('.atkGlobal-email-use');
                if (activeFormEmailUseButton) {
                  activeFormEmailUseButton.focus();
                }
              } else if (dry.isActive(closestForm)) {
                closestForm && dry.toggleActive(closestForm);
                dry.events.publish('form-toggle:close');
                el.focus();
              }
            }
            if (document.querySelector('.atkGlobalModal')) {
              dialogueFocusHandler(e, document.querySelector('.atkGlobalModal'));
            }
            dialogueFocusHandler(e, closestForm);
          }

          if (closestForm) {
            dry.toggleActive(closestForm);
            var toggleSub = dry.events.subscribe('form-toggle:close', function () {
              document.removeEventListener('keydown', handleKeyDown);
              if (toggleSub && toggleSub.remove) toggleSub.remove();
            })
            if (dry.isActive(closestForm)) {
              document.addEventListener('keydown', handleKeyDown);
            } else {
              el.focus();
            }
            closestInput.focus();
          }
        }
      } else if (closestForm) {
        evt.preventDefault();
        document.location.href = closestForm.getAttribute('action');
      }
    }

    dry.each(formDisplayToggles, function (el) {
      el.addEventListener('click', formDisplayView);
    });
  }


  //----------------------------------------------------------------------------
  //  Hamburger Nav
  //----------------------------------------------------------------------------
  var hamburgerMenu = document.querySelector('.atkOffsitePanel'),
    menuOpen = document.querySelectorAll('.atkGlobalSiteHeader .atkGlobalSiteHeader__menuTrigger');
  menuClose = document.querySelectorAll('.atkOffsitePanel .atkOffsitePanel__close'),
    pinnedNavMenuClose = document.querySelector('.atkPinnedHeader .atkOffsitePanel__close'),
    pinnedNavMenuOpen = document.querySelector('.atkPinnedHeader .atkGlobalSiteHeader__menuTrigger'),
    panelBlurSelector = 'atkOffsitePanel__blur';

  if (hamburgerMenu) {
    function openHamburgerMenu(trigger) {
      var panelBlur = document.createElement('div');
      panelBlur.classList.add(panelBlurSelector);
      document.body.classList.add('body-fixed');
      document.body.appendChild(panelBlur);

      dry.hide(pinnedNavMenuOpen);
      dry.unhide(pinnedNavMenuClose);
      dry.unhide(menuClose);

      var tabletBreakpoint = 768
      isMobile = document.body.offsetWidth < tabletBreakpoint;
      if (trigger === 'pinnedHeader') {
        dry.addClass(hamburgerMenu, 'active', 'active-pinned');

        if (isMobile) {
          menuClose[0].focus();
          document.addEventListener('click', handleHamburgerFocus);
          document.addEventListener('keydown', handleHamburgerFocus);
        } else {
          pinnedNavMenuClose.focus();
          document.addEventListener('click', navigatePinnedHeaderMenu);
          document.addEventListener('keydown', navigatePinnedHeaderMenu);
        }
      } else if (trigger === 'siteHeader') {
        dry.activate(hamburgerMenu);
        menuClose[0].focus();
        document.addEventListener('click', handleHamburgerFocus);
        document.addEventListener('keydown', handleHamburgerFocus);
      }
    }

    /** In order to maintain a logical tab order, if the hamburger menu is opened via pinned header, we should only traverse the body and footer of the hamburger menu */
    function navigatePinnedHeaderMenu(keyPressed) {
      if (keyPressed.keyCode === 27) closeHamburgerMenu();

      var pinnedHeader = document.querySelector('.atkPinnedHeader__wrapper'),
        pinnedHeaderMenuClose = pinnedHeader.querySelector('.atkOffsitePanel__close'),
        hamburgerMenuBody = document.querySelector('.atkOffsitePanel__body'),
        hamburgerMenuFooter = document.querySelector('.atkOffsitePanel__footer');

      var focusableElements = 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"]';
      var hamburgerBodyFocusableElements = Array.prototype.slice.call(hamburgerMenuBody.querySelectorAll(focusableElements)),
        hamburgerFooterFocusableElements = Array.prototype.slice.call(hamburgerMenuFooter.querySelectorAll(focusableElements));

      var pinnedHeaderHamburgerMenu = [pinnedHeaderMenuClose].concat(hamburgerBodyFocusableElements, hamburgerFooterFocusableElements);

      var firstFocusable = pinnedHeaderHamburgerMenu[0],
        lastFocusable = pinnedHeaderHamburgerMenu[pinnedHeaderHamburgerMenu.length - 1],
        firstFocusableOnHamburgerMenu = hamburgerBodyFocusableElements[0],
        isTabPressed = keyPressed.key === 'Tab' || keyPressed.keyCode === 9;

      if (!isTabPressed) {
        return;
      }

      if (keyPressed.shiftKey) {
        // IF shift key is pressed AND user is focusing on the first focusable child
        // element, move focus to the last focusable child element.
        if (document.activeElement === firstFocusable) {
          lastFocusable.focus();
          keyPressed.preventDefault();
        } else if (document.activeElement === firstFocusableOnHamburgerMenu) {
          firstFocusable.focus();
          keyPressed.preventDefault();
        }
      } else {
        // IF only the tab key is pressed AND user is focusing on the last focusable
        // child element, move focus to first focusable child element.
        if (document.activeElement === firstFocusable) {
          firstFocusableOnHamburgerMenu.focus();
          keyPressed.preventDefault();
        } else if (document.activeElement === lastFocusable) {
          firstFocusable.focus();
          keyPressed.preventDefault();
        }
      }
    }

    function handleHamburgerFocus(e) {
      if (dry.isActive(hamburgerMenu)) {
        if (e.keyCode === 27) closeHamburgerMenu();
        lockFocus(e, hamburgerMenu);
      }
    }

    function closeHamburgerMenu() {
      var panelBlur = document.querySelector('.' + panelBlurSelector);
      var pinnedHeader = document.querySelector('.atkPinnedHeader');
      document.body.classList.remove('body-fixed');
      panelBlur.parentNode.removeChild(panelBlur);

      dry.each('.expandable', function (el) {
        dry.removeClass(el, 'expanded');
      });
      dry.removeClass(hamburgerMenu, activeClass, 'active-pinned');
      dry.unhide(pinnedNavMenuOpen);
      dry.hide(pinnedNavMenuClose);

      if (pinnedHeader && pinnedHeader.classList.contains('atkPinnedHeader__is-pinned')) {
        pinnedNavMenuOpen.focus();
      } else {
        menuOpen[0].focus();
      }
    }

    function closeHamburgerMenuOnEvent(selector, evt) {
      if (evt.target.closest(selector)) {
        closeHamburgerMenu();
      }
    }

    if (menuOpen.length > 0) {
      var menuOpenClick = openHamburgerMenu.bind(this, 'siteHeader')
      dry.each(menuOpen, function (el) {
        el.addEventListener('click', menuOpenClick);
      });
      dry.each(menuClose, function (el) {
        el.addEventListener('click', closeHamburgerMenu);
      });
    }

    if (pinnedNavMenuOpen && pinnedNavMenuClose) {
      pinnedNavMenuOpen.addEventListener('click', openHamburgerMenu.bind(this, 'pinnedHeader'));
      pinnedNavMenuClose.addEventListener('click', closeHamburgerMenu);
    }

    var hamburgerShowsAccordionButton = hamburgerMenu.querySelector('.play-mega-menu-button')
    if (hamburgerShowsAccordionButton) {
      hamburgerShowsAccordionButton.setAttribute('aria-controls', 'play-nav-shows-wrapper');
      hamburgerShowsAccordionButton.addEventListener(
        'click',
        function() {
          toggleShowsContent(hamburgerShowsAccordionButton, '.atkOffsitePanel .play-nav-shows-wrapper');
        }
      );
    }

    var closeHamburgerMenuOnBlur = closeHamburgerMenuOnEvent.bind(this, '.atkOffsitePanel__blur'),
      closeHamburgerMenuOnLinkClick = closeHamburgerMenuOnEvent.bind(this, 'a');

    document.addEventListener('click', closeHamburgerMenuOnBlur);
    document.addEventListener('touchend', closeHamburgerMenuOnBlur);
    hamburgerMenu.addEventListener('click', closeHamburgerMenuOnLinkClick);
  }

  // ---------------------------------------------------------------------------
  // Log In Link - add referrer
  // ---------------------------------------------------------------------------
  var loginLinks = document.querySelectorAll('.login-link a, #hamburger-login-logout');
  if (loginLinks.length > 0) {
    function isReferable() {
      var shouldRefer = true;
      var nonReferablePaths = ['user', 'sign_in', 'sign_out', '/'];

      for (var i = nonReferablePaths.length; i > 0; i--) {
        if (location.pathname.indexOf(nonReferablePaths[i]) > 0) {
          shouldRefer = false;
          break;
        }
      };

      return shouldRefer;
    };

    dry.each(loginLinks, function (link) {
      link.addEventListener('click', function (evt) {
        evt.preventDefault();
        var logInLink = evt.target.getAttribute('href');

        if (logInLink.indexOf('next') === -1 && isReferable()) {
          var character = logInLink.indexOf('?') === -1 ? '?' : '&',
            nextParam = character + 'next=' + encodeURIComponent(location.pathname + location.search);
          logInLink += nextParam;
        }
        // WEB-1258: Browser bug with first load window.location route?
        setTimeout(function() {
          window.location = logInLink;
        }, 0);
      });
    })
  }

  // ---------------------------------------------------------------------------
  // Modal
  // ---------------------------------------------------------------------------

  var modalClass = 'atkGlobalModal',
    modalTemplate = "<button aria-label='Close' class='atkGlobalModal__close'>X</button><h2 class='atkGlobalModal__header' id='how-we-use-email-header-global' tabindex='0'>How We Use Your Email Address</h2><p id='how-we-use-email-desc-global'><em>America's Test Kitchen</em> will not sell, rent, or disclose your email address to third parties unless otherwise notified. Your email address is required to identify you for free access to content on the site. You will also receive free newsletters and notification of America's Test Kitchen specials.</p>",
    modalBlurClass = 'atkGlobalModalBlur',
    modalCount = 0,
    emailUseEl = document.querySelectorAll('.atkGlobal-email-use');

  function handleEmailModalFocus(e) {
    if (e.keyCode === 27) closeModal();
    else lockFocus(e, document.querySelector('.atkGlobalModal'));
  }

  if (emailUseEl.length > 0) {
    dry.each(emailUseEl, function (el) {
      el.addEventListener('click', function (evt) {
        evt.preventDefault();
        if (modalCount < 1) {
          var modalBlur = document.createElement('div');
          var modal = document.createElement('div');
          modalBlur.classList.add(modalBlurClass);
          modal.classList.add(modalClass);
          modal.innerHTML = modalTemplate;
          modal.setAttribute('aria-labelledby', 'how-we-use-email-header-global');
          modal.setAttribute('aria-describedby', 'how-we-use-email-desc-global');
          modal.setAttribute('aria-modal', 'true');
          modal.setAttribute('role', 'dialog');
          document.body.classList.add('body-fixed');
          document.body.appendChild(modalBlur)
          document.body.appendChild(modal);
          document.addEventListener('keydown', handleEmailModalFocus);
          document.querySelector('.atkGlobalModal__close').focus();
        }
        modalCount += 1;
      });
    });
  }

  function closeModal() {
    var atkFormDisplay = document.querySelector('.atkFormDisplay');
    var atkFormDisplayActive = atkFormDisplay.classList.contains(activeClass);
    document.body.classList.remove('body-fixed');

    var blurEl = document.querySelector('.atkGlobalModal');
    var modalEl = document.querySelector('.atkGlobalModalBlur');
    if (blurEl) blurEl.parentNode.removeChild(blurEl);
    if (modalEl) modalEl.parentNode.removeChild(modalEl);
    document.removeEventListener('keydown', handleEmailModalFocus);

    if (atkFormDisplayActive) {
      dry.deactivate(atkFormDisplay);
    }
    var activeForm = document.querySelector('.atkFormDisplay.active');
    if (activeForm) {
      activeForm.querySelector('.atkGlobal-email-use').focus();
    }

    modalCount = 0;
  }

  document.addEventListener('click', function (evt) {
    var closeClick = evt.target.closest('.atkGlobalModal__close'),
      blurClick = evt.target.closest('.atkGlobalModalBlur');
    if (closeClick || blurClick) {
      closeModal();
    }
  })

  //----------------------------------------------------------------------------
  //   Email Info Trigger
  //----------------------------------------------------------------------------

  var infoTriggerEl = document.querySelector('.form__info--trigger');
  if (infoTriggerEl) {
    infoTriggerEl.addEventListener('click', function (evt) {
      dry.toggleHidden(evt.currentTarget.nextElementSibling);
      dry.toggleAriaExpanded(evt.currentTarget);
    });
  }

  //----------------------------------------------------------------------------
  // Scroll
  //----------------------------------------------------------------------------

  (function () {
    var pinContainer = globalHeaderEl.querySelector('.atkPinnedHeader'),
      globalSiteNav = globalHeaderEl.querySelector('.atkGlobalSiteNav'),
      siteHeader = globalHeaderEl.querySelector('.atkGlobalSiteHeader'),
      currentScroll = 0,
      isPinned = false,
      mobileMax = 667,
      isMobile = document.body.offsetWidth <= mobileMax,
      pinClass = 'atkPinnedHeader__is-pinned',
      pastHeader = false,
      ticking = false,
      resizeTick,
      buffer = 20,
      headerHeight,
      pinHeaderHeight,
      siteHeaderTop,
      siteHeaderHeight,
      mobilePinHeaderHeight;

    function requestTick(callback) {
      if (!ticking) window.requestAnimationFrame(callback);
      ticking = true;
    }

    function measureScreen() {
      isMobile = document.body.offsetWidth <= mobileMax;
      mobilePinHeaderHeight = siteHeaderTop + siteHeaderHeight || 0;
      pinHeaderHeight = globalSiteNav.offsetTop;
      headerHeight = isMobile ? mobilePinHeaderHeight : pinHeaderHeight || 0;
      siteHeaderTop = siteHeader.offsetTop;
      siteHeaderHeight = siteHeader.offsetHeight;
      currentScroll = window.pageYOffset;
      pastHeader = currentScroll >= headerHeight;
    }

    function onScroll() {
      currentScroll = window.pageYOffset;
      pastHeader = (currentScroll + buffer) >= headerHeight;
      headerHeight = isMobile ? mobilePinHeaderHeight : pinHeaderHeight;
      if (pastHeader && !isPinned) {
        dry.addClass(pinContainer, pinClass);
        isPinned = true;
      } else if (!pastHeader && isPinned || currentScroll === 0) {
        dry.removeClass(pinContainer, pinClass);
        isPinned = false;

        dry.deactivate('.atkAccountNav.active');
        dry.deactivate('.atkFormDisplay.active');
      }
    }


    if (pastHeader) dry.addClass(pinContainer, pinClass);
    measureScreen();

    resizeTick = requestTick.bind(this, measureScreen);
    window.addEventListener('scroll', dry.debounce(onScroll, 50));
    window.addEventListener('resize', resizeTick);
  })();

  //----------------------------------------------------------------------------
  //  Site Nav Drop Down Trigger
  //----------------------------------------------------------------------------

  function closeExpandablesOnTouchables(evt) {
    if (isTouchEnabled(evt)) {
      evt.stopPropagation();
      dry.toggleClass(expandedClass, document.querySelector('.' + expandedClass));
      document.removeEventListener('click', closeExpandablesOnTouchables);
    }
  }

  dry.each('.expandable', function (el) {
    var expandableNavItem = el.querySelector('.atkGlobalSiteNav__menu--link');

    if (expandableNavItem) {
      expandableNavItem.addEventListener('click', function (evt) {
        if (isTouchEnabled(evt)) {
          evt.stopPropagation();
          var expandableItem = evt.target.closest('.expandable');
          var isExpanded = dry.hasClass(expandedClass, expandableItem);
          var eventAction = isExpanded ? 'removeEventListener' : 'addEventListener';
          document[eventAction]('click', closeExpandablesOnTouchables);
          dry.toggleClass(expandedClass, expandableItem);
        }
      });
    }
  });

  // Helper function to determine if this device is touch enabled
  // See: https://github.com/Modernizr/Modernizr/blob/master/feature-detects/touchevents.js

  function isTouchEnabled(evt) {
    var isTouchable = (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch),
      isSidemenu = evt.target.closest('.atkOffsitePanel'),
      isTabletOrLess = window.innerWidth < 1100;
    return isTouchable || isSidemenu || isTabletOrLess;
  }


  //----------------------------------------------------------------------------
  //  Skip Link
  //----------------------------------------------------------------------------
  // Provide screen reader users a way to skip over navigation links.
  // Technique from https://web.dev/bypass/

  var skipLink = document.getElementById('main-skip-link');
  var target = document.querySelector('main');
  if (target) {
    skipLink.setAttribute('href', '#main');
    target.setAttribute('id', 'main');
  } else {
    // fallback old behavior if page is missing <main>
    skipLink.setAttribute('href', '#');
    skipLink.addEventListener('click', function (e) {
      e.preventDefault();
      var headingSelectors = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];
      var firstHeading = document.querySelector(headingSelectors.join(', '));
      if (firstHeading) {
        firstHeading.setAttribute('tabindex', "-1");
        firstHeading.focus();
      }
    });
  }

  //----------------------------------------------------------------------------
  //  Toolbar Nav Trigger
  //----------------------------------------------------------------------------

  var navTriggerLinks = globalHeaderEl.querySelectorAll('[class$=__trigger]'),
    accountNavContainerSelector = '.atkAccountNav',
    accountNavContainers = globalHeaderEl.querySelectorAll(accountNavContainerSelector),
    accountNavLinks = globalHeaderEl.querySelectorAll('.atkAccountNav__link'),
    globalNavContainerSelector = '.atkGlobalNav',
    globalNavContainer = globalHeaderEl.querySelector(globalNavContainerSelector),
    globalNavLinks = document.querySelectorAll('.atkGlobalNav__link ');

  function toggleNav(evt) {
    dry.toggleActive(evt.currentTarget.parentNode.closest('div'));
  }

  if (navTriggerLinks.length > 0) {
    dry.each(navTriggerLinks, function (el) {
      el.addEventListener('click', function (evt) {
        evt.preventDefault();
        toggleNav(evt);
        dry.toggleAriaExpanded(evt.currentTarget)
      });

      var ul, list;
      if (el && el.parentNode) {
        ul = el.parentNode.querySelector('ul');
        if (ul) {
          list = ul.querySelectorAll('a');
        }
      }

      if (ul && list) {
        list.forEach(function (anchor) {
          anchor.addEventListener('focusout', function () {
            document.addEventListener('focusin', function () {
              const hasFocus = ul.contains(document.activeElement);
              if (!hasFocus) {
                ul.closest('div').classList.remove('active');
              }
            }, { once: true });
          });
        });
      }
    });
  }

  dry.each(accountNavLinks, function (el) {
    el.addEventListener('click', toggleNav);
  });

  dry.each(globalNavLinks, function (el) {
    el.addEventListener('click', function (evt) {
      dry.toggleActive(evt.currentTarget.closest(globalNavContainerSelector));
    });
  });

  //----------------------------------------------------------------------------
  // Toolbar All Open Containers
  // Click outside of nav to close nav.
  //----------------------------------------------------------------------------
  document.addEventListener('click', function (evt) {
    var el = evt.target,
      isTrigger = el.className && el.className.indexOf && el.className.indexOf('trigger') !== -1;

    if (!isTrigger) {
      if (globalHeaderEl.querySelector(globalNavContainerSelector + '.' + activeClass)) {
        dry.deactivate(globalNavContainer);
        return;
      }

      if (globalHeaderEl.querySelector(accountNavContainerSelector + '.' + activeClass)) {
        dry.each(accountNavContainers, dry.deactivate.bind(dry));
        return;
      }

      var activeFormDisplay = globalHeaderEl.querySelector('.atkFormDisplay.' + activeClass);
      if (activeFormDisplay && !el.closest('.atkFormDisplay') && !el.closest('.atkGlobalModal')) {
        var activeFormDisplayToggle = activeFormDisplay.querySelector('.atkFormDisplay__toggle--primary');
        dry.deactivate(activeFormDisplay);
        return;
      }

      var expandedMenu = globalHeaderEl.querySelector('.expandable.' + expandedClass);
      if (expandedMenu && !el.closest('.atkGlobalSiteNav__menu--link')) {
        dry.removeClass(
          expandedClass,
          globalHeaderEl.querySelector('.atkGlobalSiteNav__menu--item')
        );
        return;
      }
    }

  });


  //----------------------------------------------------------------------------
  //  Touch Events for Mobile Devices that Are a PITA
  //----------------------------------------------------------------------------
  // Click outside of nav to close nav.
  document.body.addEventListener('click', function () {
    return;
  });

  var touchsupport = ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0)
  if (!touchsupport) { // browser doesn't support touch
    document.documentElement.classList.add('non-touch');
  }
};

if (typeof dry.events !== 'undefined') {
  dry.events.subscribe('user:get', function () {
    dry.events.publish('dry:ui_actions:loaded');
  });
}

//----------------------------------------------------------------------------
// Global Utility for Piano Placements
//----------------------------------------------------------------------------

globalHeader.handlePianoMarketingHat = function (event, trackingData) {
  var mixpanelDistinctId = '';

  var siteToSiteKey = {
    americastestkitchen: 'atk',
    cookscountry: 'cco',
    cooksillustrated: 'cio',
  };
  
  function getPurchaseFlowParams(args, params, trackingData) {
    function parseQueryString(s) {
      var p = {};
      var key, val;
      s.split('&').forEach(function(el) {
        if (el) {
          key, val = el.split('=');
          p[key] = val;
        }
      })
      return p;
    }
    var urlParams = args.href.includes('?') ? args.href.split('?').pop() : ''
    var queryParams = parseQueryString(urlParams);
    queryParams.documentId = args.docId;
    queryParams.documentTitle = args.docTitle;
    queryParams.documentType = args.docType;
    queryParams.incode = args.incode;
    queryParams.location = trackingData.location;
    queryParams.type = trackingData.type;
    queryParams.device = trackingData.deviceType;
    if (params.email) {
      queryParams.email = params.email;
    }
    if (params.mdc) {
      queryParams.mdc = params.mdc;
    }
    if (params.trackingId) {
      queryParams.trackingId = params.trackingId;
    }

    return queryParams;
  }
  
  function getPurchaseFlowUrl(flowParams, clickDomain) {
    function getPurchaseFlowSite(clickDomain) {
      var site = 'americastestkitchen.com/'
      if (clickDomain) {
        var lowerDomain = clickDomain.toLowerCase();
        if (lowerDomain === 'cco') {
          site = 'americastestkitchen.com/cookscountry/'
        } else if (lowerDomain === 'cio') {
          site = 'americastestkitchen.com/cooksillustrated/'
        }
      }
      return site;
    };
    
    function getPurchaseFlowQueryStringParams(flowParams, site) {
      function parseQueryObject(obj) {
        var queryString = ''
        Object.keys(obj).forEach(function(key, i) {
          if (i === 0) {
            queryString += (key + "=" + obj[key])
          } else {
            if (obj[key]) {
              var words = obj[key].split(' ');
              var formatString = obj[key];
              if (words && words.length > 1) {
                queryString += ("&" + key + "=" + words.join('%20'))
              } else {
                queryString += ("&" + key + "=" + formatString)
              }
            }
          }
        })
        return queryString
      }
  
      var queryParams = {};
      if (flowParams.incode) {
        queryParams.incode = flowParams.incode;
      }
      if (flowParams.mdc) {
        queryParams.mdc = flowParams.mdc;
      }
      if (flowParams.skip_value) {
        queryParams.skip_value = flowParams.skip_value;
      }
      if (flowParams.purchaseType) {
        queryParams.purchase_type = flowParams.purchaseType;
      }
      if (mixpanelDistinctId) {
        queryParams.mixpanel_id = mixpanelDistinctId;
      }
      if (flowParams.trackingId) {
        var currentSite = document.location.hostname.split('.')[1];
        queryParams.piano_origin_site_key = siteToSiteKey[currentSite];
        queryParams.piano_tracking_id = flowParams.trackingId;
      }
      var rtParam = document.location.href;
      if (!rtParam.includes(site)) {
        var bit = rtParam.includes('?') ? '&' : '?'
        rtParam = rtParam + bit + "refreshUserToken=true";
      }
      queryParams.return_to = rtParam;
      queryParams.documentId = flowParams.documentId;
      queryParams.documentTitle = flowParams.documentTitle;
      queryParams.documentType = flowParams.documentType;
      queryParams.placement = 'MarketingHat';
      queryParams.device = flowParams.device;
      if (flowParams.location) {
        queryParams.location = flowParams.location;
      }
      if (flowParams.type) {
        queryParams.type = flowParams.type;
      }
      return parseQueryObject(queryParams);
    };

    function getSubdomain() {
      return document.location.hostname.split('.').shift();
    }

    var site = getPurchaseFlowSite(clickDomain);
    var actionParams = getPurchaseFlowQueryStringParams(flowParams, site);
    var subdomain = getSubdomain();
    return "https://" + subdomain + "." + site + "order?" + actionParams;
  };
  
  function createForm(flowUrl, flowParams) {
    var newForm = document.createElement('form');
    newForm.setAttribute('action', dry.subfolderUrl(flowUrl));
    newForm.setAttribute('method', 'POST');
    newForm.setAttribute('id', 'midas-redirect');
    var formElements = Object.keys(flowParams).map(function(key) {
      return "<input type=hidden name=" + key + " value=" + flowParams[key] + " />";
    });
    newForm.innerHTML = formElements.join('') + "<input type=hidden name=return_to value=" + document.location.href + " />"
    document.body.appendChild(newForm);
  };
  
  function trackMarketingHat(params, callback, trackingData) {
    var email, incode;
    var device = trackingData.deviceType;
    var location = trackingData.location;

    [email, incode] = [params.email, params.incode];
    if (email) {
      mixpanel.track('Email Capture', {
        device_type: device,
        email: email,
        incode: incode,
        location: location,
        name: 'Email Capture',
        status: 'Accepted',
        type: 'Join'
      })
    }
    callback();
  }

  function handleHowWeUse() {
    var hideShow;
    var button = document.getElementById('how-we-use');
    if (button.style.display === 'none') {
      hideShow = 'block';
    } else {
      hideShow = 'none';
    }
    button.style.display = hideShow;
  }

  var href = window.location.href;
  var params = event.params;
  var incode = params.incode;
  var mainElement = document.querySelector('.detail-page-main, #document-detail');
  var baristaMainElement = document.querySelector('#main');
  var baristaTitle, docTitle, docId, docType;

  if (mainElement) {
    var data = mainElement.dataset;
    [docTitle, docId, docType] = [data.documentTitle, data.documentId, data.documentType];
  } else {
    if (baristaMainElement) {
      var baristaData = baristaMainElement.dataset;
      if (baristaData.documenttitle) {
        baristaTitle = baristaData.documenttitle.split('-').join(' '); 
      }
      if (baristaData.docid) {
        docId = baristaData.doc;
      }
      if (baristaData.doctype) {
        docType = baristaData.doctype;
      }
    }
  }

  if (params.trackingId) document.cookie="piano_tracking_id=" + params.trackingId + ";path=/";
  if (params.termId) document.cookie="piano_term_id=" + params.termId + ";path=/";

  var args = {
    docTitle: docTitle || baristaTitle,
    docId: docId || null,
    docType: docType || null,
    href: href,
    incode: incode,
  }

  var flowParams = getPurchaseFlowParams(args, params, trackingData);
  var domain = params.clickDomain || params.clickdomain;
  var flowUrl = getPurchaseFlowUrl(flowParams, domain);
  var cb = function() { return document.location.href = flowUrl; };
  if (params.email) {
    createForm(flowUrl, flowParams);
    cb = function() {
      var customForm = document.getElementById('midas-redirect');
      return customForm.submit(); 
    };
  }

  if (event.eventName === 'sign-up-button') {
    trackMarketingHat(params, cb, trackingData)
  }

  if (event.eventName === 'how-we-use') {
    handleHowWeUse()
  }
};

var contentVertical = contentVertical || {};

var sites = {
  'cio' : 'https://www.americastestkitchen.com/cooksillustrated/',
  'atk' : 'https://www.americastestkitchen.com/',
  'cco' : 'https://www.americastestkitchen.com/cookscountry/'
};

//=================================================//
// [contentVertical.refreshDocumentFields]
//=================================================//
contentVertical.refreshDocumentFields = function (siteKey) {
  var docType = document.querySelector('#document_document_type option:checked').value;
  var docId   = document.getElementById('document_document_id').value;
  if (!docId) {
    alert('Please supply a Document ID');
    document.getElementById('document_document_id').focus();
    return;
  }
  dry.api.getDocument(siteKey, docType, docId, function(docResp) {
    var data = docResp.data;
    // Using sci as the default site key, this will likely be set to atk, cio, cco
    if (siteKey === 'sci') {
      siteKey = data.site_list[0];
    }
    if (!data.site_list.includes(siteKey)) {
      alert('The chosen document does not exist on this site');
      document.getElementById('document_document_id').focus();
      return;
    }
    document.querySelector("[name='json_hash[cloudinary_id]']").value =
      (data.photo ? data.photo.cloudinary_id : '') ||
      (data.episode_photo ? data.episode_photo.cloudinary_id : '');
    document.querySelector("[name='json_hash[title]']").value = dry.stripHTML(data.title);
    document.querySelector("[name='json_hash[description]']").value = dry.stripHTML(data.description);
    document.querySelector("[name='json_hash[link]']").value = sites[siteKey] + docType + '/' + data.slug;
    document.querySelector("[name='json_hash[image_url]']").value = (data.photo ? data.photo.image_url : '');
  });
}

//=================================================//
// Related form events
//=================================================//
document.addEventListener('DOMContentLoaded', function () {
  var dataButtons = document.getElementsByClassName('api-data-link');
  [].forEach.call(dataButtons, function (dataButton) {
    dataButton.addEventListener('click', function (evt) {
      evt.preventDefault();
      contentVertical.refreshDocumentFields(dataButton.id);
    });
  });
});


//==========================================
// Code to execute when document loads
//==========================================

function tngGlobalHeader() {
  var atkLink = document.querySelector('[data-marketing-key="atk-global-nav-link-ako"]');
  var tngLink = document.querySelector('[data-marketing-key="atk-global-nav-link-tng"]');
  var currentPath = document.location.pathname;
  if (currentPath.includes('tng') && atkLink && atkLink.classList.contains('current')) {
    atkLink.classList.remove('current');
    tngLink && tngLink.classList.add('current');
  }
}
dry.events.subscribe('dry:ui_actions:loaded', function () {
  window.globalHeader.initializeUI();
  tngGlobalHeader();
});
dry.events.subscribe('dry:authentication:loaded', function () {
  window.globalHeader.initializeState();
});
`;

const DryNoJquery = () => {
  return (
    <script
      crossOrigin="anonymous"
      id="global-header-js-url"
      key="global-header-js-url"
      defer
      dangerouslySetInnerHTML={{
        __html: NoJquery,
      }}
    />
  );
};

export default DryNoJquery;
